import styled, {css} from "styled-components";
import {Container} from "../common";

export const StyledComments = styled.footer`


`;

export const CommentsContainer = styled(Container)`
  background-color: #fafafa;
`;
