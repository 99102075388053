import React, {FunctionComponent} from "react";
import {CommentsContainer, StyledComments} from "./style";
import HyvorTalk from 'hyvor-talk-react'

/**
 * Placeholder which is attached under every post. Can be shadowed to
 * quickly integrate comments (like commento, Disqus, ...).
 */
const Comments: FunctionComponent = () => (
  <StyledComments>
    <CommentsContainer>
      <HyvorTalk.Embed websiteId={464} />
    </CommentsContainer>
  </StyledComments>
);

export default Comments;
